import React from 'react'
import SocialIcons from '../common/SocialIcons'

import '../../styles/components/hero-title.scss'

class HeroTitle extends React.Component {
  render() {
    return (
      <header className="hero-title container">
              <SocialIcons type="light" position="absolute" />
        <div className="container medium">
          <div className="row">
            <div className="col">
              <div className="text-align-center">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default HeroTitle