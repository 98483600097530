import React from 'react'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'
import HeroTitle from '../components/sections/hero-title'

import '../styles/pages/author.scss'

/**
* Author page (/author/:slug)
**/
const Author = ({ data, location, pageContext }) => {
    const author = data.ghostAuthor
    const posts = data.allGhostPost.edges
    const twitterUrl = author.twitter ? `https://twitter.com/${author.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = author.facebook ? `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}` : null
    let authorImage = author.profile_image ? author.profile_image : '/images/icons/avatar-male.png'
    let authorLinks = author.website || author.facebook || author.twitter ? true : false

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="profile"
            />
            <Layout>
                <HeroTitle>
                    <div className="author__id">
                        <img src={authorImage} alt={author.name} />
                        <h1>{author.name}</h1>
                    </div>
                    {author.bio && <p className="author__bio">{author.bio}</p>}
                    {authorLinks && <div className="author__links">
                        {author.website && <a className="link--plain" href={author.website} target="_blank" rel="noopener noreferrer">Website</a>}
                        {twitterUrl && <a className="link--plain" href={twitterUrl} target="_blank" rel="noopener noreferrer nofollow">Twitter</a>}
                        {facebookUrl && <a className="link--plain" href={facebookUrl} target="_blank" rel="noopener noreferrer nofollow">Facebook</a>}
                    </div>}
                </HeroTitle>
                <div className="container medium">
                    <section className="row feed-layout__three">
                        {posts.map(({ node }) => (
                            <PostCard key={node.id} post={node} columns={3} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

export default Author

export const pageQuery = graphql`
    query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
        ghostAuthor(slug: { eq: $slug }) {
            ...GhostAuthorFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {authors: {elemMatch: {slug: {eq: $slug}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`
